<template>
  <div class="card card-custom h-100">
    <div class="card-header border-0"
         v-show="hasHeaderSlot">
      <div class="card-title w-100">
        <slot name="header"></slot>
      </div>
    </div>
    <div :class="{'pt-0 pb-5' : hasHeaderSlot}"
         class="card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  computed: {
    hasHeaderSlot() {
      return !!this.$slots['header']
    },
  },
}
</script>